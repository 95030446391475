var app = {
  fancybox: function fancybox() {
    $('[data-fancybox="demo"]').fancybox({
      touch: {
        vertical: false
      }
    });
  },
  applicantReferrerAlert: function applicantReferrerAlert() {
    if (document.referrer.includes("cta.cadienttalent.com")) {
      $.fancybox.open($("#applicant_referrer_alert"), {
        closeExisting: true,
        touch: {
          vertical: false
        }
      });
    }
  },
  init: function init() {
    if ($("#demobtn").length > 0) {
      app.fancybox();
    }

    app.applicantReferrerAlert();
  }
};
$("document").ready(app.init);